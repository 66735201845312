import React from 'react'
import { CloseRounded, DoneRounded, RemoveRounded } from '@mui/icons-material'
import { colorsConfig } from '../../config/themeConfig'

const cellIconTypes = {
  check: <DoneRounded sx={{ fill: colorsConfig.mainGreen }} />,
  close: <CloseRounded sx={{ fill: colorsConfig.darkRed }} />,
  remove: <RemoveRounded sx={{ fill: colorsConfig.darkRed }} />,
}

const UITableCellIcon = ({ type }) => {
  return cellIconTypes[type]
}

export default UITableCellIcon
